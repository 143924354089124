import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function WorkshopFormatSection({children}){
    return (
        <div className="medium-light-gray-bg py-xl-5 py-4" id="tll_format">
            <Container fluid="lg">
                {children}
            </Container>
        </div>
    )
}

export const WorkshopFormatHalfPanel = ({classes, children}) => {
    return (
        <Col lg={6} xs={12}>
            <Row>
                <Col
                    xs={12}
                    lg
                    className={classes}
                >
                    <Row>
                        <Col xs={12} className="pt-1">
                            <p className="workshop-info-description">
                                {children}
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    )
}

function WorkshopStructure({startDate, endDate, timeAndDay}) {
    return (
        <div id="structure">
            <Container fluid="lg" className="py-4 py-xl-5">
                <Row className="justify-content-between align-items-center">
                    <Col xl={6} sm={12} className="mb-4 pb-3 mb-xl-0 pb-xl-0">
                        <img src="https://web-cdn.meridianuniversity.edu/site-content-images/oe-course-course-structure.webp" className="img-fluid d-none d-xl-block" alt="beautiful lake and mountain skyline" />
                        <img src="https://web-cdn.meridianuniversity.edu/site-content-images/oe-course-course-structure-mobile.webp" className="img-fluid d-block d-xl-none" alt="beautiful lake and mountain skyline"  />
                    </Col>
                    <Col xl={5} sm={12}>
                        <Row>
                            <Col xs={12}>
                                <h2 className="mb-4">Event Structure</h2>
                                <ol>
                                    <li> In-Person and Online Event from {startDate} through {endDate}.</li>
                                    <li> Multiple In-Person and Zoom sessions with faculty and recorded for participants who cannot join every session live.</li>
                                    <li> Resources, networking, and emergent community outcomes.
                                    </li>
                                    <li> Content and collaboration via Meridian's social learning platform.
                                    </li>
                                </ol>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

function WorkshopScheduleContainer({children}) {
    return(
        <div id="schedule">
            <Container fluid="lg" className="py-4 py-xl-5">
                <Row className="justify-content-between align-items-center">
                    <Col xl={{span: 6, order: '2'}} sm={{span: 12, order: '1'}} className="mb-4 pb-3 mb-xl-0 pb-xl-0">
                        <img src="https://web-cdn.meridianuniversity.edu/site-content-images/oe-course-course-schedule.webp" className="img-fluid d-none d-xl-block" alt="beautiful lake and mountain skyline" />
                        <img src="https://web-cdn.meridianuniversity.edu/site-content-images/oe-course-course-schedule-mobile.webp" className="img-fluid d-block d-xl-none" alt="beautiful desert plateau skyline"  />
                    </Col>
                    <Col xl={5} sm={12}>
                        <Row>
                            <Col xs={12}>
                                <h2 className="mb-4">Event Schedule</h2>
                                <Row>
                                    {children}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

function WorkshopScheduleWeek({date, time, children}) {
    return(
        <Col xl={6} lg={3} md={4} sm={6}>
            <div className="week white-bg">
                <div className="week-info">
                    <span className="icon">
                        <img src="https://web-cdn.meridianuniversity.edu/site-content-images/oe-course-calendar.svg" className="img-fluid" alt="calendar icon" />
                    </span>
                    <span className="date">
                        {date}
                    </span>
                    {time ? 
                    <>
                        <span className="line">|</span>
                        <span className="icon">
                            <img src="https://web-cdn.meridianuniversity.edu/site-content-images/oe-course-clock.svg" className="img-fluid" alt="calendar icon" />
                        </span>
                        <span className="time">
                            {time}
                        </span>
                    </>
                    :
                    <></>
                    }
                </div>
                <h4>{children}</h4>
            </div>
        </Col>
    )
}

function WorkshopFeesAndInfo({icon, title, fee, children}){
    return (
        <div id="accredAndFinAid">
            <Row className="py-xl-5 py-lg-4 mb-lg-4 align-items-center">
                <Col
                    xs={{span: 12, order: 2}}
                    lg={{span:6, order:1}}
                    className="pe-lg-4 pe-xl-5"
                >
                    <Row>
                        <Col xs={12} className="d-flex align-items-center mb-3">
                            <span className="d-inline-flex me-4">
                                <h3 className="workshop-format-title mb-0">{title}</h3>
                            </span>
                            <div className="meridian-blue-15-bg rounded-pill icon d-inline-flex p-2">
                                <div className="meridian-blue-bg rounded-circle p-2 me-3">
                                    <img src={icon} className="img-fluid" alt={`${title} icon`} />
                                </div>
                                <p className="workshop-fee">{fee}</p>
                            </div>  
                        </Col>
                        <Col xs={12} className="pt-1">
                            <p className="workshop-info-description">
                                {children}
                            </p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={{span: 12, order: 1}} lg={{span:6,order:2}} className="ps-lg-4 ps-xl-5 mb-4 mb-lg-0">
                    <img src="https://web-cdn.meridianuniversity.edu/site-content-images/transformative-learning-lab/tll-workshop-fees-image-xl-desktop.webp" alt="ancient egyptian god and hieroglyphics carving" className="d-none d-xl-block img-fluid" />
                    <img src="https://web-cdn.meridianuniversity.edu/site-content-images/transformative-learning-lab/tll-workshop-fees-image-desktop.webp" alt="ancient egyptian god and hieroglyphics carving" className="d-none d-lg-block d-xl-none img-fluid" />
                    <img src="https://web-cdn.meridianuniversity.edu/site-content-images/transformative-learning-lab/tll-workshop-fees-image-tablet.webp" alt="ancient egyptian god and hieroglyphics carving" className="d-none d-md-block d-lg-none img-fluid" />
                    <img src="https://web-cdn.meridianuniversity.edu/site-content-images/transformative-learning-lab/tll-workshop-fees-image-mobile.webp" alt="ancient egyptian god and hieroglyphics carving" className="d-block d-md-none img-fluid" />
                </Col>
            </Row>           
        </div>
    )
}

export { WorkshopFormatSection, WorkshopScheduleContainer, WorkshopStructure, WorkshopScheduleWeek, WorkshopFeesAndInfo }