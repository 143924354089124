import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../styles/default-header.scss';

const WorkshopHeader = ({ headline, headlineTwo, heroBackground, heroMobileBackground, subHeadline, eventDate, cta }) => {
    
        const rightChevron = <svg width="8" height="10" viewBox="0 0 8 10" fill="#FFF" xmlns="http://www.w3.org/2000/svg"><path d="M2.21089 0L7.21094 5L2.21089 10L0.79251 8.58152L4.37408 5L0.79251 1.41848L2.21089 0Z" fill="#FFF"/></svg>;
        const { width } = useWindowSize();

        return (
            <div id="mu-hero" style={{ "--img": width > 1199 ? heroBackground : heroMobileBackground }} className="ipc" >
                <Container fluid="lg">
                    <Row className="align-items-end">
                        <Col xl={8} lg={9} md={11} className="mb-md-5 ps-4 pe-5 pe-lg-0 mb-4">
                            <h1 className="mu-workshop-headline mb-0">{ headline }</h1>
                            <h2 className="mu-workshop-headline-italic mb-2">{ headlineTwo }</h2>
                            <h2 className="mu-workshop-subheadline mb-2">{ subHeadline }</h2>
                            <h3 className="w-40 event-bumper">{ eventDate }</h3>
                            {cta && <p className="mb-0 pt-2 mt-4"><a href="#tllRegistration" className="btn btn-primary">Register for the Lab {rightChevron}</a></p>}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
}

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined
    });
  
    useEffect(() => {
      // only execute all the code below in client side
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth
        });
      }
      
      // Add event listener
      window.addEventListener("resize", handleResize);
       
      // Call handler right away so state gets updated with initial window size
      handleResize();
      
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}


export default WorkshopHeader