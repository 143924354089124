import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { handleSubmit as formSubmitter } from "../../../libs/form-submitter";
import Cookies from "js-cookie";

import "../styles/stripeRegister.scss";
import '../styles/spinner.scss';

export function WorkshopNewsletterForm({ submitCampaignID, submitType, customBg, warningTxt }) {

  const tandc = (
    <p className="mb-md-0">
      By registering for this program/event, you are accepting Meridian
      University's <a href="/terms-and-conditions">Terms and Conditions.</a>
    </p>
  );

  const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

  /* Get GAUID */
  const clientId = typeof document !== "undefined" ? Cookies.get('_ga') : null;
  const gaUID = ((clientId != null) || (clientId != undefined)) ? clientId.substring(6) : "No GAUID Detected";
  
  /* Get URL Params */
  const urlRef = typeof window !== 'undefined' ? window.location.search : '';
  const url = urlRef.slice(1);
  const urlParams = new URLSearchParams(url);
  const utm_source = urlParams.has("utm_source") ? urlParams.get("utm_source") : 'NoSource';
  const utm_medium = urlParams.has("utm_medium") ? urlParams.get("utm_medium") : 'NoMedium';
  const utm_campaign = urlParams.has("utm_campaign") ? urlParams.get("utm_campaign") : 'NoCampaign';
  const utm_term = urlParams.has("utm_term") ? urlParams.get("utm_term") : 'NoTerm';
  const utm_content = urlParams.has("utm_content") ? urlParams.get("utm_content") : "NoContent";
  const utm_gclid = urlParams.has("gclid") ? urlParams.get("gclid") : "NoGCLID";

  /* Get Initial Cookie Value */
  let muSource = Cookies.get('_muSource'); 
  let muMedium = Cookies.get('_muMedium');
  let muCampaign = Cookies.get('_muCampaign'); 
  let muTerm = Cookies.get('_muTerm');
  let muContent = Cookies.get('_muContent');
  
  /* Set Cookie Value and Store for Logic App Values */
  let source;
  let medium;
  let campaign;
  let term;
  let content;
  source = ( muSource === undefined ? ( Cookies.set('_muSource', utm_source, { expires: 7 }), source = Cookies.get('_muSource') ) : ( muSource === "NoSource" && utm_source !== "NoSource" ? ( Cookies.set('_muSource', utm_source, { expires: 7 } ), source = Cookies.get('_muSource') ) : ( muSource !== "NoSource" && utm_source !== "NoSource" ? ( Cookies.set('_muSource', utm_source, { expires: 7 } ), source = Cookies.get('_muSource') ) : ( source = muSource ) ) ) );
  medium = ( muMedium === undefined ? ( Cookies.set('_muMedium', utm_medium, { expires: 7 }), medium = Cookies.get('_muMedium') ) : ( muMedium === "NoMedium" && utm_medium !== "NoMedium" ? ( Cookies.set('_muMedium', utm_medium, { expires: 7 } ), medium = Cookies.get('_muMedium') ) : ( muMedium !== "NoMedium" && utm_medium !== "NoMedium" ? ( Cookies.set('_muMedium', utm_medium, { expires: 7 } ), medium = Cookies.get('_muMedium') ) : ( medium = muMedium ) ) ) );
  campaign = ( muCampaign === undefined ? ( Cookies.set('_muCampaign', utm_campaign, { expires: 7 }), campaign = Cookies.get('_muCampaign') ) : ( muCampaign === "NoCampaign" && utm_campaign !== "NoCampaign" ? ( Cookies.set('_muCampaign', utm_campaign, { expires: 7 } ), campaign = Cookies.get('_muCampaign') ) : ( muCampaign !== "NoCampaign" && utm_campaign !== "NoCampaign" ? ( Cookies.set('_muCampaign', utm_campaign, { expires: 7 } ), campaign = Cookies.get('_muCampaign') ) : ( campaign = muCampaign ) ) ) );
  term = ( muTerm === undefined ? ( Cookies.set('_muTerm', utm_term, { expires: 7 }), term = Cookies.get('_muTerm') ) : ( muTerm === "NoTerm" && utm_term !== "NoTerm" ? ( Cookies.set('_muTerm', utm_term, { expires: 7 } ), term = Cookies.get('_muTerm') ) : ( muTerm !== "NoTerm" && utm_term !== "NoTerm" ? ( Cookies.set('_muTerm', utm_term, { expires: 7 } ), term = Cookies.get('_muTerm') ) : ( term = muTerm ) ) ) );
  content = ( muContent === undefined ? ( Cookies.set('_muContent', utm_content, { expires: 7 }), content = Cookies.get('_muContent') ) : ( muContent === "NoContent" && utm_content !== "NoContent" ? ( Cookies.set('_muContent', utm_content, { expires: 7 } ), content = Cookies.get('_muContent') ) : ( muContent !== "NoContent" && utm_content !== "NoContent" ? ( Cookies.set('_muContent', utm_content, { expires: 7 } ), content = Cookies.get('_muContent') ) : ( content = muContent ) ) ) );

  const formik = useFormik({
    initialValues: {
      submitFirstName: "",
      submitLastName: "",
      submitEmail: "",
      submitPhone: '',
      submitHowHear: 'Other',
      submitCampaignID,
      submitType,
      submitUTMSource: source,
      submitUTMMedium: medium,
      submitUTMCampaign: campaign,
      submitUTMTerm: term,
      submitUTMContent: content,
      submitGCLID: utm_gclid,
      submitUUID: gaUID,
    },

    validationSchema: Yup.object({
      submitFirstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      submitLastName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Required"),
      submitEmail: Yup.string()
        .email("Invalid email address")
        .required("Required"),
      submitPhone: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid'),
      submitHowHear: Yup.string(),
    }),

    onSubmit: () => formSubmitter(formValues, submitConfig),
  });

  const submitConfig = {
    preferThankYou: true,
    thankYouType: "rfi",
    thankYouForm: "shortform-rfi",
  };

  const formValues = formik.values;

  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
      setActive(!isActive); 
  };

  return (
    <div>
      <div id="submitting" className={isActive ? null : "show" }>
          <Row className="justify-content-center align-items-center">
              <Col xs={12} className="p-3 p-lg-4 text-center">
                  <h2 className="submitting-title">Submitting <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="2x" /></h2>
              </Col>
          </Row>
      </div>
      <Form onSubmit={formik.handleSubmit} id="form">
        <Form.Group controlId="namesGroup" className="names">
          <Form.Group  className="first">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                  id="submitFirstName"
                  name="submitFirstName"
                  type="text"
                  placeholder="First Name*"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.submitFirstName}
                  className={formik.touched.submitFirstName && formik.errors.submitFirstName ? (
                      `error`
                  ) : null}
              />
              {formik.touched.submitFirstName && formik.errors.submitFirstName ? (
                  <div className="error-text">{formik.errors.submitFirstName}</div>
              ) : null}
          </Form.Group>
          <Form.Group className="last">
              <Form.Label>Last Name*</Form.Label>
              <Form.Control
                  id="submitLastName"
                  name="submitLastName"
                  type="text"
                  placeholder="Last Name*"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.submitLastName}
                  className={formik.touched.submitLastName && formik.errors.submitLastName ? (
                      `error`
                  ) : null}
              />
              {formik.touched.submitLastName && formik.errors.submitLastName ? (
                  <div className="error-text">{formik.errors.submitLastName}</div>
              ) : null}
          </Form.Group>
      </Form.Group>
      <Form.Group controlId="emailAndRegType" className="email-regtype">
        <Form.Group className="email">
            <Form.Label>Email Address*</Form.Label>
            <Form.Control
                id="submitEmail"
                name="submitEmail"
                type="email"
                placeholder="Email Address*"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className={formik.touched.submitEmail && formik.errors.submitEmail ? (
                    `error`
                ) : null}
            />
            {formik.touched.submitEmail && formik.errors.submitEmail ? (
                <div className="error-text">{formik.errors.submitEmail}</div>
            ) : null}
        </Form.Group>
        <Form.Group className="register-type">
            <Form.Label>Phone Number*</Form.Label>
            <Form.Control
                id="submitPhone"
                name="submitPhone"
                type="phone"
                placeholder="Phone Number (Optional)"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.submitPhone}
                className={formik.touched.submitPhone && formik.errors.submitPhone ? (
                    `error`
                ) : null}
            />
            {formik.touched.submitPhone && formik.errors.submitPhone ? (
                <div className="error-text">{formik.errors.submitPhone}</div>
            ) : null}
          </Form.Group>
          <Form.Group>
            <Form.Control
              id="submtHowHear"
              name="submitHowHear"
              type="hidden"
              value="Other"
            />
          </Form.Group>
        </Form.Group>
        <Row className="align-items-center justify-content-md-between">
          <Col xs={12} xxl={9} md={8}>
            <Form.Text id="tc" className="tc" muted>
              {tandc}
            </Form.Text>
          </Col>
          <Col className="text-md-end">
            <Button
              variant="primary"
              type="submit"
              onClick={ToggleClass}
              disabled={!(formik.isValid && formik.dirty)}
            >
              Checkout
            </Button>
          </Col>
        </Row>
        </Form>
    </div>
  );
}

export function WorkshopPreviewForm({ submitCampaignID, submitType, customBg, warningTxt }) {

  const tandc = (
    <p className="mb-md-0">
      By registering for this program/event, you are accepting Meridian
      University's <a href="/terms-and-conditions">Terms and Conditions.</a>
    </p>
  );

  const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

  /* Get GAUID */
  const clientId = typeof document !== "undefined" ? Cookies.get('_ga') : null;
  const gaUID = ((clientId != null) || (clientId != undefined)) ? clientId.substring(6) : "No GAUID Detected";
  
  /* Get URL Params */
  const urlRef = typeof window !== 'undefined' ? window.location.search : '';
  const url = urlRef.slice(1);
  const urlParams = new URLSearchParams(url);
  const utm_source = urlParams.has("utm_source") ? urlParams.get("utm_source") : 'NoSource';
  const utm_medium = urlParams.has("utm_medium") ? urlParams.get("utm_medium") : 'NoMedium';
  const utm_campaign = urlParams.has("utm_campaign") ? urlParams.get("utm_campaign") : 'NoCampaign';
  const utm_term = urlParams.has("utm_term") ? urlParams.get("utm_term") : 'NoTerm';
  const utm_content = urlParams.has("utm_content") ? urlParams.get("utm_content") : "NoContent";
  const utm_gclid = urlParams.has("gclid") ? urlParams.get("gclid") : "NoGCLID";

  /* Get Initial Cookie Value */
  let muSource = Cookies.get('_muSource'); 
  let muMedium = Cookies.get('_muMedium');
  let muCampaign = Cookies.get('_muCampaign'); 
  let muTerm = Cookies.get('_muTerm');
  let muContent = Cookies.get('_muContent');
  
  /* Set Cookie Value and Store for Logic App Values */
  let source;
  let medium;
  let campaign;
  let term;
  let content;
  source = ( muSource === undefined ? ( Cookies.set('_muSource', utm_source, { expires: 7 }), source = Cookies.get('_muSource') ) : ( muSource === "NoSource" && utm_source !== "NoSource" ? ( Cookies.set('_muSource', utm_source, { expires: 7 } ), source = Cookies.get('_muSource') ) : ( muSource !== "NoSource" && utm_source !== "NoSource" ? ( Cookies.set('_muSource', utm_source, { expires: 7 } ), source = Cookies.get('_muSource') ) : ( source = muSource ) ) ) );
  medium = ( muMedium === undefined ? ( Cookies.set('_muMedium', utm_medium, { expires: 7 }), medium = Cookies.get('_muMedium') ) : ( muMedium === "NoMedium" && utm_medium !== "NoMedium" ? ( Cookies.set('_muMedium', utm_medium, { expires: 7 } ), medium = Cookies.get('_muMedium') ) : ( muMedium !== "NoMedium" && utm_medium !== "NoMedium" ? ( Cookies.set('_muMedium', utm_medium, { expires: 7 } ), medium = Cookies.get('_muMedium') ) : ( medium = muMedium ) ) ) );
  campaign = ( muCampaign === undefined ? ( Cookies.set('_muCampaign', utm_campaign, { expires: 7 }), campaign = Cookies.get('_muCampaign') ) : ( muCampaign === "NoCampaign" && utm_campaign !== "NoCampaign" ? ( Cookies.set('_muCampaign', utm_campaign, { expires: 7 } ), campaign = Cookies.get('_muCampaign') ) : ( muCampaign !== "NoCampaign" && utm_campaign !== "NoCampaign" ? ( Cookies.set('_muCampaign', utm_campaign, { expires: 7 } ), campaign = Cookies.get('_muCampaign') ) : ( campaign = muCampaign ) ) ) );
  term = ( muTerm === undefined ? ( Cookies.set('_muTerm', utm_term, { expires: 7 }), term = Cookies.get('_muTerm') ) : ( muTerm === "NoTerm" && utm_term !== "NoTerm" ? ( Cookies.set('_muTerm', utm_term, { expires: 7 } ), term = Cookies.get('_muTerm') ) : ( muTerm !== "NoTerm" && utm_term !== "NoTerm" ? ( Cookies.set('_muTerm', utm_term, { expires: 7 } ), term = Cookies.get('_muTerm') ) : ( term = muTerm ) ) ) );
  content = ( muContent === undefined ? ( Cookies.set('_muContent', utm_content, { expires: 7 }), content = Cookies.get('_muContent') ) : ( muContent === "NoContent" && utm_content !== "NoContent" ? ( Cookies.set('_muContent', utm_content, { expires: 7 } ), content = Cookies.get('_muContent') ) : ( muContent !== "NoContent" && utm_content !== "NoContent" ? ( Cookies.set('_muContent', utm_content, { expires: 7 } ), content = Cookies.get('_muContent') ) : ( content = muContent ) ) ) );

  const formik = useFormik({
    initialValues: {
      submitFirstName: "",
      submitLastName: "",
      submitEmail: "",
      submitHowHear: 'Other',
      submitCampaignID,
      submitType,
      submitUTMSource: source,
      submitUTMMedium: medium,
      submitUTMCampaign: campaign,
      submitUTMTerm: term,
      submitUTMContent: content,
      submitGCLID: utm_gclid,
      submitUUID: gaUID,
      submitForm: "Workshop Newsletter Form",
    },

    validationSchema: Yup.object({
      submitFirstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      submitLastName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Required"),
      submitEmail: Yup.string()
        .email("Invalid email address")
        .required("Required"),
      submitHowHear: Yup.string(),
    }),

    onSubmit: () => formSubmitter(formValues, submitConfig),
  });

  const submitConfig = {
    preferThankYou: true,
    thankYouType: "rfi",
    thankYouForm: "shortform-rfi",
  };

  const formValues = formik.values;

  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
      setActive(!isActive); 
  };

  return (
    <div>
      <div id="submitting" className={isActive ? null : "show" }>
          <Row className="justify-content-center align-items-center">
              <Col xs={12} className="p-3 p-lg-4 text-center">
                  <h2 className="submitting-title">Submitting <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="2x" /></h2>
              </Col>
          </Row>
      </div>
      <Form onSubmit={formik.handleSubmit} id="form">
        <Row>
          <Form.Group controlId="namesGroup" className="names">
          <Col xs={12} md={6} className="pe-md-2">
            <Form.Group >
                <Form.Label>First Name</Form.Label>
                <Form.Control
                    id="submitFirstName"
                    name="submitFirstName"
                    type="text"
                    placeholder="First Name*"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.submitFirstName}
                    className={formik.touched.submitFirstName && formik.errors.submitFirstName ? (
                        `error`
                    ) : null}
                />
                {formik.touched.submitFirstName && formik.errors.submitFirstName ? (
                    <div className="error-text">{formik.errors.submitFirstName}</div>
                ) : null}
            </Form.Group>
          </Col>
          <Col xs={12} md={6} className="ps-md-2">
          <Form.Group>
              <Form.Label>Last Name*</Form.Label>
              <Form.Control
                  id="submitLastName"
                  name="submitLastName"
                  type="text"
                  placeholder="Last Name*"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.submitLastName}
                  className={formik.touched.submitLastName && formik.errors.submitLastName ? (
                      `error`
                  ) : null}
              />
              {formik.touched.submitLastName && formik.errors.submitLastName ? (
                  <div className="error-text">{formik.errors.submitLastName}</div>
              ) : null}
          </Form.Group>
          </Col>
        </Form.Group>
        </Row>
      <Form.Group controlId="emailAndRegType" className="email-regtype">
        <Form.Group className="w-100">
            <Form.Label>Email Address*</Form.Label>
            <Form.Control
                id="submitEmail"
                name="submitEmail"
                type="email"
                placeholder="Email Address*"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className={formik.touched.submitEmail && formik.errors.submitEmail ? (
                    `error`
                ) : null}
            />
            {formik.touched.submitEmail && formik.errors.submitEmail ? (
                <div className="error-text">{formik.errors.submitEmail}</div>
            ) : null}
        </Form.Group>
      </Form.Group>
        <Row className="align-items-center justify-content-md-between">
          <Col xs={12} xl={7} md={8}>
            <Form.Text id="tc" className="tc" muted>
              {tandc}
            </Form.Text>
          </Col>
          <Col className="text-md-end">
            <Button
              variant="primary"
              type="submit"
              onClick={ToggleClass}
              disabled={!(formik.isValid && formik.dirty)}
            >
              Register For Free <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none" style={{marginTop: "-2px"}}>
  <g clip-path="url(#clip0_2159_14320)">
    <path d="M3.2602 0.763184L8.26025 5.76318L3.2602 10.7632L1.84183 9.34471L5.4234 5.76318L1.84183 2.18166L3.2602 0.763184Z" fill="#323337"/>
  </g>
  <defs>
    <clipPath id="clip0_2159_14320">
      <rect width="10" height="10" fill="white" transform="matrix(-1 0 0 1 10.051 0.763184)"/>
    </clipPath>
  </defs>
</svg>
            </Button>
          </Col>
        </Row>
        </Form>
    </div>
  );
}