import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SSRProvider from 'react-bootstrap/SSRProvider'

import CourseFAQ from '../components/online-courses/courseFAQ'
import Footer from '../components/footer'
import GdprPanel from '../components/gdpr'
import Navigation from '../components/navigation'
import PageQuote from '../components/quote'
import Seo from '../components/seo'
import WorkshopFaculty from '../components/workshop/workshopFaculty'
import WorkshopHeader from '../components/workshop/workshopHeader'
import { WorkshopNewsletterForm, WorkshopPreviewForm } from '../components/forms/workshopNewsletterForm'
import WorkshopRegistration from '../components/workshop/workshopRegistration'
import { WorkshopFormatSection, WorkshopFormatHalfPanel,  WorkshopStructure, WorkshopFeesAndInfo } from '../components/workshop/workshopFormat'
import { WorkshopIntro, WorkshopIntroImage, WorkshopIntroQuote } from '../components/workshop/workshopIntro'
import { WorkshopInformation, WorkshopInfoHalfPanel, WorkshopInfoThirdPanel, WorkshopInfoImage, WorkshopCallout } from '../components/workshop/workshopInformation'
import { WorkshopEventsSection, WorkshopEvent } from '../components/workshop/workshopEvents'
import NewsletterSignup from '../components/newsletterSignUp'

export default function DegreePage({ data }) {

    const post = data.mdx

    const shortcodes = {
        Container,
        Row,
        Col,
        CourseFAQ,
        PageQuote,
        WorkshopCallout,
        WorkshopFaculty,
        WorkshopEvent,
        WorkshopEventsSection,
        WorkshopFeesAndInfo,
        WorkshopFormatSection,
        WorkshopFormatHalfPanel,
        WorkshopInformation,
        WorkshopInfoHalfPanel,
        WorkshopInfoThirdPanel,
        WorkshopInfoImage,
        WorkshopIntro,
        WorkshopIntroImage,
        WorkshopIntroQuote,
        WorkshopNewsletterForm,
        WorkshopRegistration,
        WorkshopStructure,
        WorkshopPreviewForm
      }

    return (      
        <>  
        <SSRProvider>
            <Navigation />
            <WorkshopHeader 
                headline={data.mdx.frontmatter.headline}
                headlineTwo={data.mdx.frontmatter.headlineTwo}
                heroBackground={data.mdx.frontmatter.heroBackground}
                heroMobileBackground={data.mdx.frontmatter.heroMobileBackground}
                subHeadline={data.mdx.frontmatter.subheadline}
                eventDate={data.mdx.frontmatter.subtitle} 
                cta="true"  
            />
            <MDXProvider components={shortcodes}>
                <MDXRenderer>{post.body}</MDXRenderer>
            </MDXProvider>      
            <Footer />
            <GdprPanel />
            <NewsletterSignup />
        </SSRProvider>
        </>
    )
}

export const Head = ({data}) => (
    <Seo 
        title={data.mdx.frontmatter.metadata.Title} 
        description={data.mdx.frontmatter.metadata.Description} 
        pathname={data.mdx.frontmatter.metadata.Link}
        ogImage={data.mdx.frontmatter.metadata.ogImage}
        cardImage={data.mdx.frontmatter.metadata.cardImage} 
    />
)

export const query = graphql`
    query($slug: String!) {
        mdx(slug: { eq: $slug }) {
            frontmatter {
                heroBackground
                heroMobileBackground
                subheadline
                headline
                headlineTwo
                subtitle
                title
                metadata {
                    Description
                    Link
                    Title
                    ogImage
                    cardImage
                }
            }
            body
        }
    }   
`